import React from "react";
import { Link } from "@reach/router";
import Drawer from "../drawer";
import ResponsiveImage from "../responsive-image";
import { PhoneRingSVG, LocationSVG, HoursSVG } from "../svg";
import { parseDate, getHours, useMediaQuery, mediaQueries } from "../util";

const MenuDrawer = ({
  children,
  isOpen = true,
  addTransition = false,
  onClickHome,
}) => {
  const largeMatch = useMediaQuery(mediaQueries.large);
  const parsedDate = parseDate();
  const hours = getHours(parsedDate.day);

  return (
    <Drawer
      className={
        addTransition ? "menu-contain menu-transition" : "menu-contain"
      }
      anchor={"left"}
      isOpen={isOpen}
      aria-hidden={!isOpen.toString()}
    >
      {true ? (
        <Link to="/" className="menu-contain-logo" onClick={onClickHome}>
          <h1>
            <ResponsiveImage
              src={"/assets/home/sushizone_logo.jpg"}
              srcSet={"/assets/home/optimized/sushizone_logo.webp"}
              alt="Sushi Zone Logo"
            />
          </h1>
        </Link>
      ) : (
        <div className="menu-contain-logo" />
      )}
      <div className="menu-contain-content">
        <nav role="navigation">
          <ul>{children}</ul>
        </nav>
        <section className="menu-section">
          <div className="menu-section-contact">
            <PhoneRingSVG />
            <div>
              <h2>Contact</h2>
              <a
                href={isOpen ? "tel:9254556868" : null}
                aria-label="Contat Number"
              >
                925-455-6868
              </a>
            </div>
          </div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={
              isOpen
                ? "https://www.google.com/maps/place/Sushi+Zone/@37.6802811,-121.7493178,17z/data=!3m1!4b1!4m5!3m4!1s0x808fe768a00f4ac1:0x43a36404d2cd157d!8m2!3d37.6802811!4d-121.7471291"
                : null
            }
            className="menu-section-address"
          >
            <LocationSVG />
            <div>
              <h2>Locate</h2>
              <address>
                <div>4094 East Avenue</div>
                <div>Livermore, CA 94583</div>
              </address>
            </div>
          </a>
          <div className="menu-section-hours">
            <div>
              <p>({parsedDate.date})</p>
              <HoursSVG />
            </div>
            <div>
              <h2>Today's Hour</h2>
              {hours.allDay ? <div>{hours.allDay}</div> : null}
              {hours.lunch ? <div>(L) {hours.lunch}</div> : null}
              {hours.dinner ? <div>(D) {hours.dinner}</div> : null}
            </div>
          </div>
        </section>
      </div>
    </Drawer>
  );
};

export default MenuDrawer;
